import $ from 'jquery';
import eventTracker from './event_tracker';

window.ClubSoda.driverButtonValidations = (canBeMarkedDelivered, scheduleEntryId) => {
  const flashContent = message => (
    `
      <div class='grid-x'>
        <div class='cell medium-10 medium-offset-1'>
          <div class='callout flash alert' data-closable>
            <p>${message}</p>
            <button class='close-button' aria-label='Dismiss alert' data-close type='button'>
              <span aria-hidden>&times;</span>
            </button>
          </div>
        </div>
      </div>
    `
  );

  $('#mark-delivered').click(function() {
    eventTracker.track('driver clicked mark delivered', {
      id: scheduleEntryId
    });

    const confirmResult = confirm(
      'A notification will be sent to all customers saying their food is ready for pickup. Are you sure you want to mark this delivery as complete?'
    );

    if (confirmResult) {
      eventTracker.track('driver confirmed mark delivered', {
        id: scheduleEntryId
      });

      if (canBeMarkedDelivered) {
        $.ajaxSetup({
          headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          }
        });

        $.ajax({
          url: `${window.location.href}/mark_delivered/${scheduleEntryId}`,
          method: 'PATCH'
        });
      } else {
        eventTracker.track('too soon to mark delivered', {
          id: scheduleEntryId
        });

        $('.flashes').html(
          flashContent(
            "Oops, you're too early! You can only mark this delivery complete closer to the delivery time."
          )
        );
      }
    } else {
      eventTracker.track('driver did not confirm mark delivered', {
        id: scheduleEntryId
      });
    }
  });
};
