window.ClubSoda.OrderItemMultipleSizes = () => {
  document.getElementsByName('order_item[size_index]').forEach(item => {
    item.addEventListener('change', event => {
      const {price} = event.target.dataset;
      const {priceIndex} = event.target.dataset;
      const choicesWithMultiplePrices = document.querySelectorAll(`[data-price${priceIndex}]`);

      const menuItemPrice = parseFloat(price).toFixed(2);
      document.getElementById('menu-item-price').innerHTML = `$${menuItemPrice}`;

      choicesWithMultiplePrices.forEach(value => {
        const choicePrice = value.dataset[`price${priceIndex}`];
        value.innerHTML = `+ $${choicePrice}`;
      });
    });
  });
};
