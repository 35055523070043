import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle"
export default class extends Controller {
  static classes = ["active"];

  static targets = ["item"];

  toggle(event) {
    event.preventDefault();
    this.itemTargets.forEach(item => {
      item.classList.toggle(this.activeClass);
    });
  }
}
