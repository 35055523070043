window.ClubSoda.createHubspotForm = (element) => {
  const script = document.createElement('script');
  script.src = "//js.hsforms.net/forms/v2.js";

  const defaultHubspotFormId = "63135bbc-8cc8-4f46-a3fd-14aa5acf64f4";
  const formId = element.dataset.formid || defaultHubspotFormId;

  script.addEventListener('load', ()=>{
    if(window.hbspt) {
      window.hbspt.forms.create({
      portalId: "1703639",
      formId,
      target: `#${element.id}`
      });
    }
  });

  element.appendChild(script);
};

document.addEventListener('turbo:load', () => {
  const hubspotElement = document.getElementById('hubspot-content');

  if(hubspotElement) {
    ClubSoda.createHubspotForm(hubspotElement);
  }
});

