import {Controller} from "@hotwired/stimulus";
import eventTracker from '../event_tracker';

/* eslint-disable class-methods-use-this */
export default class extends Controller {
    trackUsage(event) {
      const {trackingEvent, eventParams} = event.currentTarget.dataset;
      const paramObject = eventParams ? JSON.parse(eventParams) : {};
      eventTracker.track(trackingEvent, paramObject);
    }
}
