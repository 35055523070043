import { Controller } from "@hotwired/stimulus";
import DataTable from "datatables.net-dt";

export default class extends Controller {
  static values = { config: Object };


  initialize() {
    this.table = new DataTable(this.element, this.configValue); // Store the DataTable instance
  }
}
