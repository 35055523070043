selectTopCategoryAsDefault = function() {
  if (!$('.menu-category-nav-title-option.is-active').length) {
    $('.menu-category-nav-title-option').first().addClass('is-active');
  } else if ($('.menu-category-nav-title-option.is-active').length > 1) {
    $('.menu-category-nav-title-option').first().removeClass('is-active');
  };
};

activateMenuCategoryOnClick = function() {
  $('.menu-category-nav li a').click(function() {
    $('.menu-category-nav li a').removeClass('is-active');
    $(this).addClass('is-active');
  });
};

$(document).on('update.zf.magellan', () => {
  selectTopCategoryAsDefault();
});

$(document).on('turbo:load', () => {
  selectTopCategoryAsDefault();
  activateMenuCategoryOnClick();
});

window.ClubSoda.makeMenuCategoryNavSticky = () => {
  var menuCategoryNav = document.querySelector('.menu-category-nav-full-width-container');
  var menuCategoryNavMobile = document.querySelector('.menu-category-nav-mobile-container-full-width');

  if (menuCategoryNav === null || menuCategoryNavMobile === null) {
    return;
  }

  var menuCategoryNavTop = menuCategoryNav.offsetTop;
  var menuCategoryNavMobileTop = menuCategoryNavMobile.offsetTop;

  window.onscroll = function() {
    var offset = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    if (offset >= menuCategoryNavTop) {
      menuCategoryNav.classList.add('sticky', 'is-stuck');
    }
    else {
      menuCategoryNav.classList.remove('sticky', 'is-stuck');
    }

    if (offset >= menuCategoryNavMobileTop) {
      menuCategoryNavMobile.classList.add('sticky', 'is-stuck');
    }
    else {
      menuCategoryNavMobile.classList.remove('sticky', 'is-stuck');
    }
  }
}
