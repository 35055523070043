import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        const reviewRating = document.getElementById('order-review-numeric-rating');
        if (reviewRating) {
            this.fillInStars(reviewRating.dataset.reviewRating);
        }
    }

    handleClick(event) {
        const {rating} = event.currentTarget.dataset;
        const {numericRating} = event.currentTarget.dataset;
        this.toggleOrderReviewReasons(numericRating);
        this.fillInStars(numericRating);
        this.setHiddenRatingFieldValue(rating);

        this.dispatch('ratingClickHandled');
    }

    toggleOrderReviewReasons(rating) {
        const positiveReasons = document.getElementById('positive-reasons');
        const negativeReasons = document.getElementById('negative-reasons');
        if (rating > 2 && positiveReasons.classList.contains('hidden')) {
            positiveReasons.classList.remove('hidden');
            negativeReasons.classList.add('hidden');
            this.uncheckAllReasons();
        } else if (rating <= 2 && negativeReasons.classList.contains('hidden')) {
            positiveReasons.classList.add('hidden');
            negativeReasons.classList.remove('hidden');
            this.uncheckAllReasons();
        }
    }

    uncheckAllReasons() {
        const reasons = document.getElementsByClassName('order-review-reason-checkbox');
        Array.from(reasons).forEach(function (reason) {
            reason.checked = false;
        });
    }

    fillInStars(rating) {
        const stars = document.querySelectorAll('.rating-star');
        stars.forEach(function (star) {
            if (star.dataset.numericRating <= rating) {
                star.classList.add('active');
            } else {
                star.classList.remove('active');
            }
        });
    }

    setHiddenRatingFieldValue(rating) {
        const hiddenRatingField = document.getElementById('order_review_food_rating');
        hiddenRatingField.value = rating;
    }
}
