import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import mask from "jquery-mask-plugin";

// Connects to data-controller="form-validation"
export default class extends Controller {
  initialize() {
    this.initializeInputMasks();
    this.formValidation();
  }

  formInputUpdate() {
    if (!$(this).val() || !$(this).val().length) {
      $(this).removeClass('has-value');
    }
    $(this).parents('form').trigger('change');
  };

  validateAllFormsWithRequiredFields() {
    $('input[required="required"], [data-required=true]').each(function() {
      $(this).parents('form').trigger('change');
    });
  }

  initializeInputMasks() {
    $('input[type="tel"]').mask('+1 (000) 000-0000');
    $('#user_phone_confirmation_token').mask('0000');
  }

  formValidation() {
    $('input[required="required"]').on('blur keyup', this.formInputUpdate);

    $('input[required="required"], select[required="required"], [data-required=true]').on('focus', function() {
      $(this).addClass('has-value');
    });

    $('select[required="required"]').val(function(index, value) {
      if (value.length > 0) {
        $(this).addClass('has-value');
      }
      return value;
    });

    function checkCheckboxGroupValidity(form) {
      const checkboxGroups = $(form).find("*[data-required-checkbox-group=true]");
      const validCheckboxGroups = Array.from(checkboxGroups).reduce((validCount, checkboxGroup) => {
          const checkedOptions = $(checkboxGroup).find(":checkbox:checked").length;
          const requiredOptions = $(checkboxGroup).data("minimum-choices-allowed");

          if (checkedOptions >= requiredOptions) {
            ++validCount;
          }

          return validCount;
      }, 0);

      return checkboxGroups.length == validCheckboxGroups;
    };

    $('form[data-client-side-validation=true]').on('change', function() {
      const isValid = $(this)[0].checkValidity() && checkCheckboxGroupValidity(this);
      const $submitButton = $(this).find('input[type="submit"]');

      $submitButton.prop('disabled', !isValid);
    });

    this.validateAllFormsWithRequiredFields();
  };
}
