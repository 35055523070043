import ClipboardJS from "clipboard";

window.ClubSoda.clipboards = () => {
    const clipboardClass = '.btn-copy-clipboard';
    const clipboards = document.querySelectorAll(clipboardClass);

    if (clipboards.length) {
        const clipboard = new ClipboardJS(clipboardClass);
        clipboard.on('success', function(e) {
            const tooltip = e.trigger.closest('.tooltip-click').querySelector('.relish-tooltip');
            tooltip.style.visibility = 'visible';

            // Hide the tooltip after 2 seconds
            setTimeout(function() {
                tooltip.style.visibility = 'hidden';
            }, 2000);
            e.clearSelection();
        });
    }
};
