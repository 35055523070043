import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="user-registration"
export default class extends Controller {
  connect() {
    if (this.element.selectedOptions.length === 1) {
      const value = this.element.selectedOptions[0].value;
      this.locationChanged(value);
    }
  }

  change() {
    const { value } = this.element;
    this.locationChanged(value);
  }

  currentLocation() {
    this.dispatch('locationOptionChanged', { detail: { element: this.element.selectedOptions[0] } });
  }

  locationChanged(value) {
    const { turboFrame, url } = this.element.dataset;
    const parsedUrl = new URL(url);
    const frame = document.getElementById(turboFrame);

    this.dispatch('locationOptionChanged', { detail: { element: this.element.selectedOptions[0] } });

    parsedUrl.searchParams.append('selected_drop', value);
    frame.src = parsedUrl.href;
  }
}
