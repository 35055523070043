import braintree from "braintree-web";
import ClubSoda from "./club_soda";

window.ClubSoda.loadBraintree = ({authorization, hideZipcode = false, submit}) => {
  const form = document.querySelector('#new_payment_method');
  const btnSubmit = document.querySelector('#submit-button');
  const paymentNonce = document.querySelector('#payment_method_nonce');
  const errorMessage = document.querySelector('#error-message');
  const fieldsMapping = {
    number: {
      selector: '#card-number',
      placeholder: 'Credit Card Number'
    },
    cvv: {
      selector: '#cvv',
      placeholder: '123'
    },
    expirationDate: {
      selector: '#expiration-date',
      placeholder: 'MM/YYYY'
    },
    postalCode: {
      selector: '#postal-code',
      placeholder: 'Zip Code'
    },
  };

  function generateFields() {
    if (hideZipcode) {
      delete fieldsMapping.postalCode;
    }

    return fieldsMapping;
  }

  function parseErrorMessages(tokenizeErr) {
    switch (tokenizeErr.code) {
      case 'HOSTED_FIELDS_FIELDS_EMPTY':
        return 'All fields are empty! Please fill out the form.';
      case 'HOSTED_FIELDS_FIELDS_INVALID':
        var parsedFields = tokenizeErr.details.invalidFieldKeys.map(function(field) {
          switch(field) {
            case 'cvv':
              return 'CVV';
            case 'postalCode':
              return 'Zip Code';
            case 'number':
              return 'Credit Card Number';
            default:
              // field is lowerCamelCase ex: expirationDate
              // The first replace puts spaces before capital letters -> "expiration Date"
              // The second replace capitalizes the first letter -> "Expiration Date"
              // This could cause problems if we get like "otherCVV", but we'll
              // worry about that if it comes up.
              return field
                .replace(/([A-Z])/g, ' $1')
                .replace(/^./, function(str) { return str.toUpperCase(); });
          }
        });

        return `Some fields are invalid: ${parsedFields.join(', ')}.`;
      case 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE':
        return 'This payment method already exists in your vault.';
      case 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED':
        return 'CVV did not pass verification';
      case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
        return 'Make sure the credit card provided is valid!';
      case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
        return 'Network error occurred when processing the payment method. Please try again.';
      default:
        return 'Something wrong happened! Please contact support.';
    }
  }

  braintree.client.create({
    authorization
  }, function (clientErr, clientInstance) {
    if (clientErr) {
      console.warn(clientErr);
      return;
    }

    braintree.hostedFields.create({
      client: clientInstance,
      styles: {
        'input': {
          'font-size': '14px'
        },
        'input.invalid': {
          'color': 'red'
        },
        'input.valid': {
          'color': 'green'
        }
      },
      fields: generateFields()
    }, function (hostedFieldsErr, hostedFieldsInstance) {
      if (hostedFieldsErr) {
        console.error(hostedFieldsErr);
        return;
      }

      btnSubmit.removeAttribute('disabled');
      btnSubmit.addEventListener('click', function (event) {
        event.preventDefault();

        errorMessage.innerHTML = '';
        btnSubmit.setAttribute('disabled', 'true');

        hostedFieldsInstance.tokenize(function (tokenizeErr, payload) {
          if (tokenizeErr) {
            errorMessage.innerHTML =
              `<div id="payment-method-error-explanation"><p>${parseErrorMessages(tokenizeErr)}</p></div>`;
            btnSubmit.removeAttribute('disabled');

            
          } else {
            paymentNonce.value = payload.nonce;
            submit(form);
          }
        });
      }, false);
    });
  });
};
