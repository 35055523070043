import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="auto-submit-form"
export default class extends Controller {
  connect() {
    this.element.addEventListener("change", () => {
      this.submitForm();
    });
  }

  submitForm() {
    this.element.requestSubmit();
  }

  // Use submitDebouncedForm instead of submitForm to prevent multiple form submissions. It's a good idea
  // to use this method when you have a form with an input that updates frequently, like a text input field.
  submitDebouncedForm(event) {
    clearTimeout(this.timeout);
    event.preventDefault()
    this.timeout = setTimeout(() => {
      this.submitForm();
    }, 500);
  }
}
