window.ClubSoda.menuMaximumChoicesCap = () => {
  $(document).on('click', '.maximum-choices-cap', function() {
    const groupCheckboxes = document.getElementsByName($(this).attr('name'));
    let numberOfCheckedItems = 0;
    const maximumChoicesAllowed = $(this).data('maximumChoicesAllowed') || 0;

    if (maximumChoicesAllowed > 0) {
      for(let i = 0; i < groupCheckboxes.length; i++) {
        if(groupCheckboxes[i].checked) {
          numberOfCheckedItems++;
        }
      }

      if (numberOfCheckedItems > maximumChoicesAllowed) {
        alert(`You can't select more than ${maximumChoicesAllowed} options!`);
        return false;
      }
    }
  });
};
