positionAddToCartButtonAtBottom = () => {
  if ($('#menu-item-modal').length && (window.innerHeight > $('#menu-item-modal').height())) {
    const marginBottom = window.innerHeight - ($('#menu-item-modal').offset().top + $('#menu-item-modal').height());
    $('#option-form-submit').css('transform',`translateY(-${marginBottom}px)`);
  }
};

$(window).resize(() => {
  positionAddToCartButtonAtBottom();
});
